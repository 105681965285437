.account-container {
    width: 100%;
    max-width: 768px;
    height: 100vh;
    margin: 0 auto;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  
  .account-header {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    margin-right: 15px;
    color: #000;
  }
  
  .header-title {
    font-size: 16px;
    font-weight: 500;
    border:none;
  }
  
  .account-form {
    padding: 24px 20px;
  }
  
  .form-group {
    margin-bottom: 24px;
    display: flex;

  }
  
  .input-label {
    font-size: 14px;
    color: var(-grayscale-gray-600);
    font-family: "Pretendard-Medium", Helvetica;
    font-weight: 500;
    margin-bottom: 8px;
    /* display: inline-block; */
    padding:  4% 20px;
    width: 150px;
    line-height: 20px;
    margin-right:20px;
  }
  
  .input-field {
    width: 100%;
    border: none;
    padding: 8px 0;
    font-size: 15px;
    outline: none;
    color: var(-grayscale-gray-900);
    font-family: "Pretendard-Medium", Helvetica;
    font-weight: 600; 
  }
  
  .input-field::placeholder {
    color: #CCCCCC;
  }
  

  .form-group {
    margin-bottom: 24px;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    height: 48px;
    width:312px;
  }
  .save-button {
    width: 100% !important;
    padding: 15px;
    background-color: var(--grayscale-gray-100);
    border: 1px solid;
    border-color: var(--grayscale-gray-400);
    font-family: "Pretendard-Medium", Helvetica;
    border-radius: 8px;
    color: var(--grayscale-gray-600);
    font-size: 14px;
    cursor: pointer;
    margin-top: 32px;
    transition: all 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #EBEBEB;
  }
  .save-button:disabled {
    cursor: default;
  }
  
  .save-button.active {
    background-color: #000;
    color: white;
    width:2000px;
    padding: auto;
    
  }
  
  .save-button.active:hover {
    opacity: 0.9;
  }
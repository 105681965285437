@import './styles/typography.css';
@import './styles/color.css';
:root {
  input[type="password"] {
    font-family: password-font, sans-serif;
    font-size: 16px;
    font:large-caption;
    letter-spacing: 1px;
  }
  
  @font-face {
    font-family: password-font;
    src: local("Arial");
    unicode-range: U+002A; /* '*' 문자만 사용 */
  }
  color-scheme: light; 
  /* 다크모드 무력화 */

}

body{
  overflow-y: scroll;
  -ms-overflow-style: none;
 scrollbar-width: none;
 -ms-overflow-style: none;
 scrollbar-gutter: stable
}
	/* 크롬, 사파리, 오페라 스크롤바 옵션 */


::-webkit-scrollbar {
	display: none;
  width: 0;
  height: 0;
}



[data-grayscale-mode="light"] {
  --grayscale-color-ver2-BG-blue: rgba(245, 246, 255, 1);
  --grayscale-color-ver2-BG-magen: rgba(251, 247, 255, 1);
  --grayscale-color-ver2-blue-100: rgba(163, 213, 255, 1);
  --grayscale-color-ver2-blue-200: rgba(118, 192, 255, 1);
  --grayscale-color-ver2-blue-300: rgba(48, 160, 255, 1);
  --grayscale-color-ver2-blue-400: rgba(0, 123, 227, 1);
  --grayscale-color-ver2-blue-50: rgba(209, 234, 255, 1);
  --grayscale-color-ver2-blue-900: rgba(0, 48, 115, 1);
  --grayscale-color-ver2-magen-100: rgba(247, 180, 255, 1);
  --grayscale-color-ver2-magen-50: rgba(250, 209, 255, 1);
  --grayscale-color-ver2-magen-900: rgba(163, 46, 178, 1);
}

[data-grayscale-mode="dark"] {
  --grayscale-color-ver2-BG-blue: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-BG-magen: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-100: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-200: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-300: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-400: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-50: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-blue-900: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-magen-100: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-magen-50: rgba(255, 255, 255, 1);
  --grayscale-color-ver2-magen-900: rgba(255, 255, 255, 1);
}

.App {
  width: 360px; 
  height: 800px;
  margin: 0 auto !important;
  padding: 0;  
}
/* 사용자가 다크모드일 시 */
/* Chrome, Edge에서 라이트모드로 전환되는거 확인, 삼성인터넷은 브라우저 자체가 지원X */

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: light;
    forced-colors: light;
  }
}

/* 초기값 (데스크탑 기준) */
html {
  font-size: 62.5% !important; /* 16px * 0.625 = 10px */
}
body{
  font-size: 1.6rem;  /* 1.6 * 10px = 16px */
}



/* 모바일 퍼스트 */
/* 가장 작은 디바이스를 우선으로 작성한 뒤 뷰포트를 확대하면서 분기점 추가가 */
 
/* 소형 모바일 (가로 360 이하) */
@media (max-width: 360px) {
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.4rem; /* 1.4 * 10px = 14px */
  }
  .App {
    width: 31.5rem; /* 10px * 31.5 = 315px */
    margin: 0 auto;
  }
}

/* 중/대형 모바일 (가로 361px ~ 812px 정도) */
@media (min-width: 361px) and (max-width: 812px) {
  /* html {
    font-size: 62.5%; 
  }
  body {
    font-size: 1.5rem; 
  } */
  /* 15px * 24 = 360px  */

}


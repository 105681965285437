:root{
  /* white */
  --white: #FFFFFF;

  /* gray */
  --gray-50: #FBFBFB;
  --gray-100: #F2F2F2;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #CFCFCF;
  --gray-500: #A8A8A8;
  --gray-600: #767676;
  --gray-700: #585858;
  --gray-800: #444444;
  --gray-900: #242424;

  /* magenta */
  --magen-50:#FAD1FF;
  --magen-100:#F7B4FF;
  --magen-300:#C66FD1;
  --magen-900:#A32EB2;


  /* red */
  --red-50: #FDF2F3;
  --red-100: #F4C0C1;
  --red-300: #D92B2B;

  /* blue */
  --blue-50: #D1E4FF;
  --blue-100: #A3D5FF;
  --blue-200: #76C0FF;
  --blue-300: #30A0FF;
  --blue-400: #007BE3;
  --blue-500: #006AC4;
  --blue-900: #003073;
}
* {
    box-sizing: border-box;
  }
  
body, html {
    height: 100%;
  }
  .mypage-container {
    width: 100%;
    height: auto; 
    background-color: white;
    display: flex;
    flex-direction: column; /* 세로 방향 레이아웃 */
    position: relative;
    overflow-y: auto; 
  }
  
  .mypage-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    color: #000;
  }
  .mypage-title{

width:auto;
height: auto;
        color: var(--grayscale-gray-900);
        font-family: "Pretendard-SemiBold", Helvetica;
        font-size: 16px;
        font-weight: 600;
        left: 0;
        line-height: normal;
        top: 0;
        white-space: nowrap;
      margin-left:20px;
      display:  flex;
      flex-direction: column;
        justify-content: center;
        margin-left:20px;
        padding-left: 20px;
        margin-bottom: 5px;
        margin-bottom: 16px;
  }
  
  .menu-list {
    flex: 1; /* 남은 공간 모두 차지 */
    overflow-y: auto; /* 스크롤 가능하게 */
    padding-bottom: 14px; /* bottom-buttons 높이만큼 여백 추가 */
  }
  
  .menu-item {
    width: 100%;
    padding: 15px 20px;
    text-align: center;
    border: none;
    background-color: #ffffff;
    border: 1px solid;
    border-color:var(--grayscale-gray-100);
    font-family: "Pretendard-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color:var(--grayscale-gray-900);
    transition: background-color 0.2s ease;
    margin-bottom: 6px;
  }
  
  .menu-item:hover {
    background-color: #F8F8F8;
    /* height: calc(100vh - 60px - 140px); */
  }
  
  .bottom-buttons {
    position: sticky; /* 고정 위치 */
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top:500px;
    margin-bottom: 40px;

  }
  
  .account-button, .logout-button {
    width:100%;
    margin: 0 auto;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .account-button {
    border: 1px solid #EBEBEB;
    background: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--grayscale-gray-600);
    border-radius: 24px;
    height: 48px;
    left: 0;
    top: 0;
    width: 312px;

      
  }
  
  .logout-button {
    border: none;
    background: #000;
    color: white;
    cursor: pointer;
        background-color: var(--grayscale-gray-900);
        border-radius: 24px;
        height: 48px;
        left: 0;
        top: 0;
        width: 312px;
    
      
  }
  
  .account-button:hover {
    background-color: #F8F8F8;
  }
  
  .logout-button:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .bottom-buttons {
      padding: 15px;
    }
  
    .menu-item {
      padding: 12px 15px;
    }
  }
/* 캘린더 CSS파트 */

.disabled-date {
  color: var(--gray-500, #fff) !important;
  cursor: not-allowed !important;
  border: none !important;
 }
 
 .disabled-date:hover {
  cursor: not-allowed !important;
 }
 
 .react-calendar {
  width: auto;
  max-width: 100%;
  height: auto;
  background: white;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 0.8rem 1rem;
 }
 
 .react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr) !important;
  gap: 0;
  row-gap: 0.8rem;
  column-gap: 0.5rem !important;
  justify-content: start;
    width: fit-content;
 }
 
 .react-calendar--doubleView {
  width: 70rem;
 }
 
 .react-calendar__month-view__weekdays__weekday abbr[title]::after {
  content: '';
 }
 
 .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
 }
 
 .react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
 }
 
 .react-calendar__navigation {
  display: none;
  height: 4.4rem;
  margin-bottom: 1em;
 }
 
 .react-calendar__navigation button {
  min-width: 4.4rem;
  background: none;
 }
 
 .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  color: #767676;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0;
  justify-content: center;
  align-items: center;
 }
 
 .react-calendar__month-view__weekdays__weekday {
  max-width: 4.2rem;
  margin-left: 0.4rem;
  display: flex;
  width: auto;
  height: 2rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
 }
 
 .react-calendar__tile {
  transition: background-color 0.3s ease;
  display: flex;
  max-width: 4.2rem;
  min-height: 4.4rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  row-gap: 0.3rem;
  column-gap: 0.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--gray-200, #EEE);
  background: var(--white);
  color: var(--gray-800, #444);
  text-align: center;
  font-family: Pretendard;
  font-size: var(--Size-14, 1.4rem);
  font-weight: 400;
  line-height: var(--Height-16, 1.6rem);
  letter-spacing: -0.35px;
 }
 
 .month-button {
  border: 0.1rem solid;
  flex: 1 1 auto;
  border-color: #e4e4e4;
  background-color: #ffffff;
  color: #242424;
  font-size: 1.4rem;
  font-weight: 400;
  height: 4.5rem;
  width: 4.5rem !important;
  border-radius: 3rem !important;
 }
 
 .monthview-year-buttons {
  display: grid;
  gap: auto;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  justify-items: stretch;
  margin-top: 7rem;
  margin-left: 4rem;
 }
 
 .monthview-year-button {
  border: 0.1rem solid;
  flex: 0 0 auto;
  border-color: #e4e4e4;
  background-color: #ffffff;
  color: #242424;
  font-size: 1.4rem;
  font-weight: 400;
  height: 4.5rem;
  width: 4.5rem !important;
  border-radius: 3rem !important;
 }
 
 .selected-date {
  border: 0.1rem solid var(--blue-900, #003073);
  background-color: var(--blue-50, #D1EAFF) !important;
  font-family: Pretendard;
  font-size: var(--font-size-14, 1.4rem);
  font-weight: var(--font-md, 500);
  line-height: var(--line-height-16, 1.6rem);
  letter-spacing: -0.35px;
 }
 .react-calendar__month-view__days__day--neighboringMonth{
  border: 0.1rem solid var(--gray-100);  /* 1px */
  background: var(--gray-50);
  color: var(--gray-600, #767676);
 }